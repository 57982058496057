<template>
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-sm-6 col">
        <img :src="img" alt="404" class="img-fluid" />
        <div class="text-center"><a href="/">Back to Home</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

export default {
  data() {
    return {
      img: "img/svg/404-animate.svg",
    };
  },
};
</script>

<style lang="scss">
a:hover {
  text-decoration: none;
  font-weight: bold;
}
</style>
